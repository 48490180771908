var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editable && !_vm.disabled
    ? _c("c-btn", {
        attrs: { label: "상세업무", icon: "info_outline" },
        scopedSlots: _vm._u(
          [
            {
              key: "tooltip",
              fn: function () {
                return [
                  _c(
                    "q-popup-proxy",
                    {
                      attrs: {
                        "transition-show": "scale",
                        "transition-hide": "scale",
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card bg-dark text-white card-border p-3 text-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mb-2",
                                  staticStyle: {
                                    "font-size": "1.2rem",
                                    "font-weight": "bolder",
                                  },
                                },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "info_outline" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.improveRelationTask
                                          .relationTableTitle
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _vm.isExistsTask
                                ? _c("div", { staticClass: "opacity-8" }, [
                                    _c("span", { staticClass: "pl-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.improveRelationTask
                                            .relationTableNavi
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.isExistsTask
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-border text-start p-3",
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$comm.convertEnter(
                                          _vm.improveRelationTask
                                            .relationTableContents
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3153176671
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }